
.story-container{
    font-family: 'Open Sans';
    margin:40px 0;
    .story-timeline{
        position: relative;
        display:flex;
        max-width: 70%;
        padding-top:10px;
        padding-bottom: 20px;
        left:50%;
        transform: translate(-50%,0);
        overflow: hidden;
        margin-bottom:30px;
        .story-timeline-container{
            position:relative;
            cursor:move;
            cursor:grab;
            span{
                text-decoration: none;
                margin:20px;
                font-size: 20px;
                font-weight: bold;
                cursor:pointer;
                padding-bottom: 5px;
                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                -khtml-user-select: none; /* Konqueror HTML */
                -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                                    supported by Chrome, Edge, Opera and Firefox */
                
            }

        }
    }
    .story-content{
        position: relative;
        display:flex;
        max-width: 70%;
        max-height:400px;
        padding-top:10px;
        padding-bottom: 20px;
        left:50%;
        transform: translate(-50%,0);
        overflow: hidden;
        margin-bottom:30px;
        p{
            position:absolute;
            bottom:0%;
            margin-bottom: 40px;
            margin-left:40px;
            font-size: 30px;
            color:white;
            font-weight: bold;
            z-index: 1;
            
        }
        .story-content-details{
            position:relative;
            display:flex;
            transition: all 1s ease;
                img{
                    max-width: 100%;
                    min-width:100%;
                    object-fit: cover;
                    // background-size:cover;
                }
                
        }
    }

    .story-text{
        position: relative;
        display:flex;
        max-width:70%;
        left:50%;
        transform: translate(-50%,0);
        text-align: left;
        overflow:hidden;
        .story-content-content{
            display:flex;
            position:relative;
            div{
                p{
                    font-weight: bold;
                    text-transform: uppercase;
                    margin-bottom:20px;
                    font-size: 20px;
                }
                span{
                    width:100%;
                }
            }
        }
    }
}


.cardholder{
    position: relative;
    width: 20em;
    height:27em;
    perspective: 1000px;
    margin-top:50px;
    margin-bottom:50px;
    cursor:pointer;
}

.card{
    position:absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all 1s ease;
}

.front-face{
    display:flex;
    flex-direction: column;
    position:absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    background: rgb(240,240,240);
    border-radius: 0.5rem;
    box-shadow: 2px 2px 10px 2px rgba(0,0,0,0.2);
    align-items: center;
    
    .cover{
        width:100%;
        height: 10.5em;
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0.5em;
        background: rgb(168,193,188);

        img{
            object-fit: cover;
            width: inherit;
            height: inherit;
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
        }
        .arrowkey{
            position:absolute;
            right:0%;
            margin:10px;
            color:rgb(240,240,240);

            &:hover{
                transform: scale(1.4);
            }
        }
    }

    .content{

        $avatar-height: 170px;
        $avatar-width: 170px;

        position:absolute;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .avatar{
            position:relative;
            top:80px;
            width: $avatar-width;
            height: $avatar-height;
            border-radius: 50%;
            box-shadow: 0 0 0 4px rgba(240,240,240);

            .circle{
                background: red;
                width: 500px;
                height: 500px;
            }
     
             img{
                 object-fit: cover;
                 width: $avatar-width;
                 height: $avatar-height;
                 border-radius: 50%;
             }
     
         }
    }

  

    .name{
        margin-top:90px;
        font-family: 'Merriweather';
        font-size: 22px;
        font-weight: 700;
        letter-spacing: 0.05em;
    }

    .designation{
        margin-top:10px;
        font-family: 'Open Sans';
        font-size: 16px;
        font-weight: 100;
        letter-spacing: 0.05em;
    }

    .separator{
        margin-top:10px;
        margin-bottom:15px;
        width: 150px;
        height: 2px;
        background: green;
        opacity: 0.3;
    }

}

.back-face{
    display:flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    transform: rotateY(180deg);
    backface-visibility: hidden;
    background: rgb(168,193,188);
    border-radius: 0.5rem;

    .arrowkey{
        position:absolute;
        right:0%;
        margin:10px;
        color:rgb(240,240,240);
        cursor: pointer;

        &:hover{
            transform: scale(1.4);
        }
    }

    .paragraph{
        position: absolute;
        top: 13%;
        width:90%;
        height:70%;
        background: rgba(240,240,240,0.3);
        font-family: 'Open Sans';
        border-radius: 0.5rem;
        // box-shadow: 2px 0px 2px 2px rgba(0,0,0,0.1);
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p{
            margin:15px;
            text-align: justify;
            text-justify: inter-word;
        }
    }

    .social-media{
        $logo-width:35px;
        $logo-height:35px;
        position:absolute;
        margin-bottom: 20px;
        bottom:0%;

        a{
            margin-left:20px;
            margin-right:20px;

            .media-icon-youtube{
                width: $logo-width;
                height: $logo-width;
                color:rgb(240, 240, 240);
                &:hover{
                    color:red;
                }
            }

            .media-icon-facebook{
                width: $logo-width;
                height: $logo-height;
                color:rgb(240, 240, 240);
                &:hover{
                    color:darkblue;
                }
            }

            .media-icon-twitter{
                width: $logo-width;
                height: $logo-height;
                color:rgb(240, 240, 240);
                &:hover{
                    color:skyblue;
                }
            }
        }
    }
}
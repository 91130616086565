
$bg-color: #CADAE5;
$link-color: #3A7CCE;
$hover-color: #44BCC5;

.blogcard{
	position:relative;
	font-family: 'Open Sans';
	width: 20em;
	height: 27em;
	box-shadow: 0 1px 32px rgba(0,0,0, 0.12), 0 1px 16px rgba(0,0,0, 0.24);
	margin: 2.5em 0.6em 3.75em;
	&:hover{
		transform: translateY(-1px);
		box-shadow: 0 1px 32px rgba(0,0,0, 0.3), 0 1px 16px rgba(0,0,0, 0.3);
	}
	.img-container {
		background-repeat: no-repeat;
		background-size: 75%;
		background-position: center;
		background-size: cover;
		width:100%;
		height: 45%;
	}
	.share-btn {
		position: relative;
		background: #FDFDFD;
		border-radius: 100%;
		width: 45px;
		height: 45px;
		top: -1.75em;
		right: 5%;
		text-align: center;
		vertical-align: middle;
		box-shadow: 0 1px 8px rgba(0,0,0, 0.12), 0 1px 4px rgba(0,0,0, 0.24);
		float: right;
		cursor: pointer;
		outline: none;
		.fa {
			position: relative;
			color: #444;
			font-size: 1.8em;
			top: 0.475em;
			left: 0;
		}
		&:hover {
			box-shadow: 0 1px 12px rgba(0,0,0, 0.22), 0 1px 6px rgba(0,0,0, 0.3);
			transform: scale(1.05);
			transition: all 500ms ease-in-out, transform 500ms ease-in-out 100ms;
			.fa {
				color: #41D57A;
				top: 0.475em;
				transform: scale(1.05);
				transition: color 350ms ease-in-out 0ms, transform 500ms ease-in-out 100ms;
			}
		}
		&:active {
			transform: scale(0.9);
			transition: transform 300ms ease-in-out;
		}
	}
	.post-meta {
		color: #888;
		font-size: 0.8em;
		letter-spacing: 0.02em;
		line-height: 1.5;
		padding-left: 1px;
		vertical-align: middle;
		a {
			color: $link-color;
			font-size: 1.05em;
			letter-spacing: 0.5px;
			text-decoration: none;
			&:hover {
				color: $hover-color;
				transition: color 400ms ease-in-out;
			}
		}
		.fa-calendar {
			position: relative;
			color: #777;
			top: 3px;
			padding-right: 5px;
		}
		i{
			margin-left:2px;
		}
	}
	.post-text {
		padding: 11px 18px 15px;
		.post-title {
			font-family: 'Merriweather';
			font-weight: 900;
			font-size: 1.6em;
			// font-weight: 700;
			letter-spacing: 0.5px;
			margin: 12px 0 18px;
			cursor: pointer;
		}
		.post-desc {
			color: rgba(#000, 0.75);
			font-size: 0.9em;
			letter-spacing: 0.2px;
			line-height: 1.3;
			overflow: hidden;
			text-overflow: ellipsis;
			height: 75px;
			width: 100%;
			white-space: prewrap; 
			padding: 3px 2px;
		}
		.fa-circle-o {
			position: relative;
			color: #666;
			font-size: 6px;
			padding: 0 5px 0 3.5px;
		}
	}
}

.author-container {
	display:flex;
	position:absolute;
	align-items: center;
	margin-left:10px;
	margin-bottom: 10px;
	bottom:0px;

	span {
		position: relative;
		width: 45px;
		height: 45px;
		border-radius: 100%;
		margin-right: 10px;
		box-sizing: border-box;
		img {
			width: 45px;
			height: auto;
		}
	}
	p {
		display: inline;
		position: relative;
		font-size: 0.9em;
		top: -3px;
		left: 18px;
	
		.author-name{
			color:$link-color;
		}
	}
}

.welcome-school{
    // display:flex;
    // justify-content: center;
    // align-items: center;
    // padding-bottom:10px;
    // padding-top:10px;
    // padding-left:20px;
    // padding-right:20px;
    // font-family: 'Open Sans';
    // background:rgb(240,255,240);
    // height:500px;
    // width:80%;
    // margin-left:auto;
    // margin-right:auto;
    img{
        width:50%;
        object-fit: cover;
    }
    .welcome-content{
        width:80%;
        padding: 50px;
        text-align: left;
        margin-left:auto;
        margin-right:auto;
        .welcome-title{
            font-size:20px;
            color:rgb(100,100,100);
        }
        .welcome-school-name{
            font-size:30px;
            font-weight:bold;
        }
        .welcome-details{
            font-weight:100;
            font-size:18px;
            padding-top:30px;
            line-height: 30px;
            text-align: justify;
        }
    }
}
@keyframes slideUp {
    0%{
     transform: translateY(100px);
     opacity: 0;
    } 
    90%{
     transform: translateY(10px);
     opacity: 1;
    }
    100%{
     transform: translateY(0px);
    }
 }
 
 .carousel-container{
     position:relative;
     display:flex;
     justify-content: center;
     align-items: center;
     background:black;
     overflow: hidden;
     font-family: 'Open Sans';
     img{
         position: absolute;
         max-width:100%;
         width:100%;
         object-fit: cover;
         opacity:0;
         transition: opacity 5s ease,transform 5s ease;
     }
     .carousel-photo-title{
         position:absolute;
         font-size:50px;
         color:white;
         font-weight: 500;
         opacity: 0;
         transform: translateY(200px);
         text-align: center;
         transition: opacity 1s ease, transform 1.2s ease;
     }
 }
 

.mission-container{
   margin: 30px; 

   .mission-image{
       position: relative;
       width:90%;
       height:50%;
       background-color: rgb(168,193,188);
    .mission-bg{
       width:80%;
       height:80%;
       background-color: red; 
    }
    img{
        position:relative;
        width:100%;
        height:100%;
        left:10%;
        top:10%;
        object-fit: cover;
    }
    .mission-label{
        display:flex;
        align-items: center;
        justify-content: center;
        position:absolute;
        background-color: rgba(168,193,188,0.8);
        box-shadow: 2px 2px 2px rgba(0,0,0,0.3);
        width:50%;
        height:15%;
        right:-15%;
        bottom:10%;
        text-align: center;
        p{
            color:white;
            font-size:25px;
        }
    }
   }
    .mission-content-container{
        display:flex;
        flex-direction: column;
        justify-content: center;
        width:100%;
        height:100%;
        margin:20px;
        text-align: left;
        .mission-title{
            font-size:30px;
            font-weight: bold;
            text-align: center;
        }
        .mission-content{
            font-weight:100;
            font-size:18px;
            padding-top:30px;
            line-height: 30px;
            margin-right:20px;
        }

    }
}
